import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Layout from "../components/utils/layout"
import SEO from "../components/utils/seo"
import dots from "../images/bgs/dots.svg"

const NotFoundPage = () => (
  <Layout image={dots} full>
    <SEO title="404: Not found" />
    <Container>
      <Row className="justify-content-center" style={{marginTop: "40px"}}>
        <h1>NOT FOUND</h1>
      </Row>
      <Row className="justify-content-center">
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </Row>
    </Container>
  </Layout>
)

export default NotFoundPage
